/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import {
    REMOVE_SITE,
    UPDATE_SITES,
    UPDATE_SITE,
    UPDATE_ELEMENT,
    REMOVE_ELEMENT,
    UPDATE_LANGUAGES,
    UPDATE_TEXTS,
    UPDATE_TEXT,
    REMOVE_LANGUAGE,
    UPDATE_LANGUAGE,
    REMOVE_TEXTS,
    REMOVE_TEXT,
    UPDATE_SITE_URL,
} from 'src/storage/actions/siteActions';
import produce from "immer";
import _ from "lodash";

const initialState = {
    sites: [],
    initialized: false,
    siteUrl: null,
    textsInit: false,
    texts: [],
    languages: [],
};

const sitesReducer = (state = initialState, action) => {

    switch (action.type) {

        case UPDATE_SITE_URL:
            const { url } = action.payload;
            return produce(state, draft => {
                draft.siteUrl = url;
            });

        case REMOVE_LANGUAGE:
            const { lan } = action.payload;
            return produce(state, draft => {
                let index = _.findIndex(state.languages, {'id': lan.id});
                if(index !== -1){
                    draft.languages = state.languages.filter(l => l.id !== lan.id);
                }
            });

        case REMOVE_TEXTS:
            const { elementId } = action.payload;
            return produce(state, draft => {
                draft.texts = state.texts.filter(t => t.element_id !== elementId);
            });

        case REMOVE_TEXT:
            const { textId } = action.payload;
            return produce(state, draft => {
                draft.texts = state.texts.filter(t => t.id !== textId);
            });
            // const { elementId } = action.payload;
            // return produce(state, draft => {
            //     draft.texts = state.texts.filter(t => t.element_id !== elementId);
            // });

        case UPDATE_LANGUAGE:
            const { language } = action.payload;
            return produce(state, draft => {
                let index = _.findIndex(state.languages, {'id': language.id});
                if(index !== -1){
                    draft.languages[index] = language;
                } else {
                    draft.languages = state.languages.concat([language]);
                }
            });

        case UPDATE_TEXT:
            const { text } = action.payload;
            // return state;
            return produce(state, draft => {
                let index = _.findIndex(state.texts, {'id': text.id});
                if(index !== -1){
                    draft.texts[index] = text;
                } else {
                    draft.texts = state.texts.concat([text]);
                }
            });

        case UPDATE_LANGUAGES:
            const { languages } = action.payload;
            return produce(state, draft => {
                draft.languages = languages;
            });

        case UPDATE_TEXTS:
            const { texts } = action.payload;
            return produce(state, draft => {
                draft.texts = texts;
                draft.textsInit = true;
            });

        case REMOVE_ELEMENT: {
            const { remElement } = action.payload;
            return produce(state, draft => {
                let index = _.findIndex(state.sites, {'id': parseInt(remElement.site)});
                if(index !== -1){
                    draft.sites[index].elements = state.sites[index].elements.filter(e => e.id !== remElement.id);
                }
            });
        }

        case UPDATE_ELEMENT: {
            const { element } = action.payload;
            return produce(state, draft => {
                let siteIndex = _.findIndex(state.sites, {'id': parseInt(element.site)});
                if(siteIndex !== -1){
                    let elementIndex = _.findIndex(state.sites[siteIndex].elements, {'id': element.id});
                    if(elementIndex !== -1){
                        draft.sites[siteIndex].elements[elementIndex] = element;
                    } else {
                        draft.sites[siteIndex].elements = state.sites[siteIndex].elements.concat([element]);
                    }
                }
            })
        }

        case UPDATE_SITES: {
            const { data } = action.payload;
            return produce(state, draft => {
                draft.sites = data;
                draft.initialized = true;
            })
        }

        case UPDATE_SITE: {
            const { site } = action.payload;
            return produce(state, draft => {
                let index = _.findIndex(state.sites, {'id': site.id});
                if(index !== -1){
                    // TODO: if this ECMA2018 works better then lodash, we should change it everywhere
                    draft.sites[index] = {...draft.sites[index], ...site};
                    // draft.products[index] = _.merge(draft.products[index], product);
                } else {
                    draft.sites = state.sites.concat([site]);
                }
            });
        }

        case REMOVE_SITE: {
            const { siteId } = action.payload;
            return produce(state, draft => {
                draft.sites = state.sites.filter(v => v.id !== parseInt(siteId));
            });
        }

        default: {
            return state;
        }
    }
};

export default sitesReducer;
