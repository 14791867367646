import React, {useEffect, useState} from "react";
import {Button, CircularProgress, Typography} from "@mui/material";
import {useSelector} from "react-redux";
import {Box, useTheme} from "@mui/system";
import Chart from "react-apexcharts";

function VisitorPerDay({data, isEditing, onSave}) {

    const theme = useTheme();
    const [count, setCount] = useState(0);
    const [series, setSeries] = useState([]);
    const { visitors, initialized } = useSelector(state => state.shop_visitors);

    const options = {
        colors: [theme.palette.primary.main],
        chart: {
            type: 'line',
            stacked: false,
            height: 350,
            zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true
            },
            toolbar: {
                show: false,
                offsetX: 0,
                offsetY: 0,
                autoSelected: 'zoom'
            }
        },
        dataLabels: {
            enabled: false,
        },
        markers: {
            size: 0,
        },
        // yaxis: {
        //     labels: {
        //         formatter: function (val) {
        //             if(account.unit === "eur"){
        //                 return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(val);
        //             } else {
        //                 return val.toFixed(2) + ' ' + getUnit(account.unit);
        //             }
        //         },
        //     },
        // },
        xaxis: {
            type: 'datetime',
        },
        // tooltip: {
        //     shared: false,
        //     y: {
        //         formatter: function (val) {
        //             if(account.unit === "eur"){
        //                 return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(val);
        //             } else {
        //                 return val.toFixed(2) + ' ' + getUnit(account.unit);
        //             }
        //         }
        //     }
        // }
    };

    useEffect(() => {
        if (count !== visitors.length) {
            setCount(visitors.length);
            const dateStrings = visitors.filter((v) => v.last_visit !== null).map((v) => v.last_visit);
            const dateObjects = dateStrings.reduce((acc, dateString) => {
                const date = new Date(dateString);
                const day = date.toDateString();
                if (!acc[day]) {
                    acc[day] = { day, count: 0 };
                }
                acc[day].count++;
                return acc;
            }, {});

            // sort the date objects in ascending order
            const result = Object.values(dateObjects).sort((a, b) => new Date(a.day) - new Date(b.day));

            setSeries([
                {
                    name: "Besucher pro Tag",
                    data: result.map((r) => {
                        return { x: new Date(r.day), y: r.count };
                    }),
                },
            ]);
        }
    }, [initialized, visitors]);

    // useEffect(() => {
    //     if(count !== visitors.length){
    //         setCount(visitors.length);
    //         const dateStrings = visitors.filter(v => v.last_visit !== null).map(v => v.last_visit);
    //         const dateObjects = dateStrings.reduce((acc, dateString) => {
    //             const date = new Date(dateString);
    //             const day = date.toDateString();
    //             if (!acc[day]) {
    //                 acc[day] = { day, count: 0 };
    //             }
    //             acc[day].count++;
    //             return acc;
    //         }, {});
    //         const result = Object.values(dateObjects);
    //
    //         setSeries([
    //             {
    //                 name: "Besucher pro Tag",
    //                 data: result.map(r => {
    //                     return {x: new Date(r.day), y: r.count};
    //                 })
    //             }
    //         ])
    //
    //     }
    // }, [initialized, visitors]);

    return (
        <Box sx={{height: '90%', pt: 5}}>
            {isEditing ? (
                <>
                    <div style={{display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center'}}>
                        <Box style={{textAlign: 'center', marginBottom: 50}}>
                            <Box>
                                <Typography variant="subtitle1">
                                    Dieses Widget bietet keine Einstellungen an
                                </Typography>
                            </Box>
                            <Box>
                                <Button onClick={() => onSave(data)}>
                                    Speichern
                                </Button>
                            </Box>
                        </Box>
                    </div>
                </>
            ) : (
                <>
                    {!initialized ? (
                        <div style={{display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center'}}>
                            <CircularProgress />
                        </div>
                    ) : (
                        <>
                            <Chart
                                series={series}
                                options={options}
                                type="line"
                                width="100%"
                                height='90%'
                            />
                        </>
                    )}
                </>
            )}
        </Box>
    )
}

export default VisitorPerDay;