export const UPDATE_SITES = '@cms-sites/update-sites';
export const UPDATE_LANGUAGES = '@cms-sites/update-languages';
export const UPDATE_LANGUAGE = '@cms-sites/update-language';
export const REMOVE_LANGUAGE = '@cms-sites/remove-language';
export const REMOVE_TEXTS = '@cms-sites/remove-texts';
export const UPDATE_TEXTS = '@cms-sites/update-texts';
export const UPDATE_TEXT = '@cms-sites/update-text';
export const UPDATE_SITE = '@cms-sites/update-site';
export const UPDATE_ELEMENT = '@cms-sites/update-element';
export const REMOVE_ELEMENT = '@cms-sites/remove-element';
export const UPDATE_SITE_URL = '@cms-sites/update-site-url';
export const REMOVE_SITE = '@cms-sites/remove-site';
export const REMOVE_TEXT = '@cms-sites/remove-single-text';

export function updateSiteUrl(url){
    return dispatch => dispatch({
        type: UPDATE_SITE_URL,
        payload: {
            url
        }
    })
}

export function updateLanguage(language){
    return dispatch => dispatch({
        type: UPDATE_LANGUAGE,
        payload: {
            language
        }
    })
}

export function removeLanguage(lan){
    return dispatch => dispatch({
        type: REMOVE_LANGUAGE,
        payload: {
            lan
        }
    })
}

export function updateSites(data) {
    return (dispatch) => dispatch({
        type: UPDATE_SITES,
        payload: {
            data
        },
    });
}

export function removeSingleText(textId){
    return dispatch => dispatch({
        type: REMOVE_TEXT,
        payload: {
            textId
        }
    })
}

export function removeText(elementId){
    return dispatch => dispatch({
        type: REMOVE_TEXTS,
        payload: {
            elementId
        }
    })
}

export function updateLanguages(languages){
    return (dispatch) => dispatch({
        type: UPDATE_LANGUAGES,
        payload: {
            languages
        }
    })
}

export function updateTexts(texts){
    return (dispatch) => dispatch({
        type: UPDATE_TEXTS,
        payload: {
            texts
        }
    })
}

export function updateText(text){
    return (dispatch) => dispatch({
        type: UPDATE_TEXT,
        payload: {
            text
        }
    })
}

export function updateSite(site) {
    return (dispatch) => dispatch({
        type: UPDATE_SITE,
        payload: {
            site
        }
    })
}

export function updateSiteElement(element) {
    return (dispatch) => dispatch({
        type: UPDATE_ELEMENT,
        payload: {
            element
        }
    })
}

export function removeSite(siteId) {
    return (dispatch) => dispatch({
        type: REMOVE_SITE,
        payload: {
            siteId
        }
    })
}

export function removeSiteElement(remElement) {
    return (dispatch) => dispatch({
        type: REMOVE_ELEMENT,
        payload: {
            remElement
        }
    })
}