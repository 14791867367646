/* eslint-disable react/no-array-index-key */
import React, {lazy, Suspense} from 'react';
import AuthGuard from "./omnia/components/general/auth/AuthGuard";
import {mergeRoutes} from "src/omnia/routes-composer";
import {Layout as OmniaLayout} from "src/omnia/components/layouts/dashboard/index";
import {Navigate, Outlet} from "react-router";

const WebsiteComponent = lazy(() => import('src/views/website/IndexView'));
const ProductComponent = lazy(() => import('src/views/products/ProductView'));
const SettingsComponent = lazy(() => import('src/views/settings'));
const ProductOverviewComponent = lazy(() => import('src/views/products/IndexView'));
const OrdersComponent = lazy(() => import('src/views/orders/order-overview'));
const OrderComponent = lazy(() => import('src/views/orders/order-details'));
const NewOrderComponent = lazy(() => import('src/views/orders/order-create'));
const VisitorIndexComponent = lazy(() => import('src/views/customers/customer-overview'));
const VisitorComponent = lazy(() => import('src/views/customers/customer-details'));
const SiteDetailComponent =  lazy(() => import('src/views/website/SiteView'));

export const getRoutes = () => {
    return mergeRoutes([
        {
            path: '/shop',
            element: (
                <AuthGuard>
                    <OmniaLayout>
                        <Suspense>
                            <Outlet/>
                        </Suspense>
                    </OmniaLayout>
                </AuthGuard>
            ),
            children: [
                {
                    path: '/shop',
                    element: <Navigate to="/shop/products/overview"/>
                },
                {
                    path: '/shop/website',
                    element: <WebsiteComponent />
                },
                {
                    path: '/shop/product/:productId',
                    element: <ProductComponent />
                },
                {
                    path: '/shop/settings',
                    element: <SettingsComponent />
                },
                {
                    path: '/shop/products/overview',
                    element: <ProductOverviewComponent />
                },
                {
                    path: '/shop/products/new',
                    element: <ProductComponent />
                },
                {
                    path: '/shop/orders',
                    element: <OrdersComponent />
                },
                {
                    path: '/shop/order/:orderId',
                    element: <OrderComponent />
                },
                {
                    path: '/shop/new_order',
                    element: <NewOrderComponent />
                },
                {
                    path: '/shop/visitors/:visitorId?',
                    element: <VisitorIndexComponent />
                },
                {
                    path: '/shop/visitors/add',
                    element: <VisitorComponent />
                },
                {
                    path: '/shop/website',
                    element: <WebsiteComponent />
                },
                {
                    path: '/shop/website/site/:siteId?',
                    element: <SiteDetailComponent />
                }
            ]
        },
    ]);
}
