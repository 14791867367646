/* eslint-disable react/no-array-index-key */
import {useDispatch, useSelector} from "react-redux";
import useSocket from "./omnia/hooks/use-socket";
import {removeSite, updateSite, updateSites, updateSiteUrl} from "./storage/actions/siteActions";
import {
    removeVisitor,
    updateVisitor,
    updateVisitorActivity,
    updateVisitors,
    updateVisitorVisibility
} from "./storage/actions/visitorsActions";
import {
    removeOrder,
    removeShippingZone,
    removeVoucher, updateOrder,
    updateOrders,
    updateShippingZone,
    updateShippingZones,
    updateVoucher,
    updateVouchers
} from "./storage/actions/ordersActions";
import {
    removeProduct,
    removeProductAttribute, updateProduct,
    updateProductAttribute,
    updateProductAttributes,
    updateProducts
} from "./storage/actions/productsActions";
import {useEffect} from "react";
import useApi from "./omnia/hooks/use-api";

function Sockets() {

    const dispatch = useDispatch();
    const { user } = useSelector(state => state.account);
    const { get } = useApi();

    useSocket('shop/admin', (message) => {

        if(message['type'] === 'shopUrl'){
            dispatch(updateSiteUrl(message['url']));
        }

        if(message['type'] === 'allOrders'){
            dispatch(updateOrders(message['orders']));
        }

        if(message['type'] === 'allSites'){
            dispatch(updateSites(message['sites']));
        }

        if(message['type'] === 'allProducts'){
            dispatch(updateProducts(message['products']));
        }

        if(message['type'] === 'allVouchers'){
            dispatch(updateVouchers(message['vouchers']));
        }

        if(message['type'] === 'allVisitors'){
            dispatch(updateVisitors(message['visitors']));
        }

        if(message['type'] === 'allShippingZones'){
            dispatch(updateShippingZones(message['shipping_zones']));
        }

        if(message['type'] === 'allProductAttributes'){
            dispatch(updateProductAttributes(message['productAttributes']));
        }

        if(message['type'] === 'voucherUpdate'){
            dispatch(updateVoucher(message['voucher']));
        }

        if(message['type'] === 'voucherRemoval'){
            dispatch(removeVoucher(message['voucherId']));
        }

        if(message['type'] === 'shippingZoneUpdate'){
            dispatch(updateShippingZone(message['zone']));
        }

        if(message['type'] === 'shippingZoneRemoval'){
            dispatch(removeShippingZone(message['zoneId']));
        }

        if(message['type'] === 'productAttributeUpdate'){
            dispatch(updateProductAttribute(message['productAttribute']));
        }

        if(message['type'] === 'productAttributeRemoval'){
            dispatch(removeProductAttribute(message['productAttribute']['id']));
        }

        if(message['type'] === 'productUpdate'){
            dispatch(updateProduct(message['product']));
        }

        if(message['type'] === 'productRemoval'){
            dispatch(removeProduct(message['product_id']));
        }

        if(message['type'] === 'orderUpdate'){
            dispatch(updateOrder(message['order']));
        }

        if(message['type'] === 'removeOrder'){
            dispatch(removeOrder(message['orderId']));
        }

        if(message['type'] === 'siteUpdate'){
            dispatch(updateSite(message['site']));
        }

        if(message['type'] === 'removeSite'){
            dispatch(removeSite(message['siteId']));
        }

        if(message['type'] === 'visitorUpdate'){
            dispatch(updateVisitor(message['visitor']));
        }

        if(message['type'] === 'visitorRemoval'){
            dispatch(removeVisitor(message['visitor_id']));
        }

        if(message['type'] === 'visitorActivity'){
            dispatch(updateVisitorActivity(message['activity']));
        }

        if(message['type'] === 'visitorVisibility'){
            dispatch(updateVisitorVisibility(message['visitor_id'], message['status']));
        }

    });

    // useEffect(() => {
    //     if(user){
    //         get('shop/orders').then((response) => {
    //             dispatch(updateOrders(response));
    //         });
    //         // get('shop/visitors').then((response) => {
    //         //     dispatch(updateVisitors(response));
    //         // });
    //     }
    // }, [user]);

    return null;

}

export default Sockets;
