import { buildRootReducer} from "../../omnia/utils/build-root-reducer";

import productsReducer from "./productsReducer";
import visitorsReducer from "./visitorsReducer";
import ordersReducer from "./ordersReducer";
import sitesReducer from "./sitesReducer";

const rootReducer = buildRootReducer({
    sites: sitesReducer,
    orders: ordersReducer,
    shop_visitors: visitorsReducer,
    products: productsReducer
});

export default rootReducer;
