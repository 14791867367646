export const UPDATE_VISITORS = '@visitors/update-visitors';
export const UPDATE_VISITOR = '@visitors/update-visitor';
export const REMOVE_VISITOR = '@visitors/remove-visitor';
export const UPDATE_VISITOR_ACTIVITY = '@visitors/remove-visitor-activity';
export const UPDATE_VISITOR_VISIBILITY = '@visitors/remove-visitor-visibility';


export function updateVisitorVisibility(visitor_id, status){
    return dispatch => dispatch({
        type: UPDATE_VISITOR_VISIBILITY,
        payload: {
            visitor_id, status
        }
    })
}

export function updateVisitorActivity(activity){
    return dispatch => dispatch({
        type: UPDATE_VISITOR_ACTIVITY,
        payload: {
            activity
        }
    })
}

export function updateVisitors(data) {
    return (dispatch) => dispatch({
        type: UPDATE_VISITORS,
        payload: {
            data
        }
    });
}

export function updateVisitor(visitor) {
    return (dispatch) => dispatch({
        type: UPDATE_VISITOR,
        payload: {
            visitor
        }
    })
}

export function removeVisitor(visitorId) {
    return (dispatch) => dispatch({
        type: REMOVE_VISITOR,
        payload: {
            visitorId
        }
    })
}