import React, {useEffect, useState} from "react";
import {
    Button,
    CardActions,
    Box,
    CardContent,
    CircularProgress,
    FormControl,
    InputLabel,
    Select,
    Typography, Grid, TextField, MenuItem
} from "@mui/material";
import {useSelector} from "react-redux";
import {useTheme} from "@mui/system";
import NumberFormat from "react-number-format";
import {User} from "react-feather";
import {Truck} from "react-feather";
import {DollarSign} from "react-feather";

function KeyShopNumber({data, widget, isEditing, onSave}) {

    const theme = useTheme();
    const [number, setNumber] = useState(0);
    const [keyNumber, setKeyNumber] = useState("Besucherzahl");
    const [days, setDays] = useState(30);
    const [name, setName] = useState("Neues Widget");
    const { visitors, initialized } = useSelector(state => state.shop_visitors);
    const { orders } = useSelector(state => state.orders);

    const handleSave = () => {
        onSave(JSON.stringify({
            name: name,
            keyNumber: keyNumber,
            days: days
        }))
    }

    useEffect(() => {
        if(data){
            const parsed = JSON.parse(data);
            setName(parsed.name);
            setKeyNumber(parsed.keyNumber);
            setDays(parsed.days);
        }
    }, [data]);

    useEffect(() => {
        if(keyNumber === "Besucherzahl"){
            setNumber(visitors.filter(v => {
                return v.last_visit && (new Date(v.last_visit) > new Date((new Date().getTime()) - (days * 24 * 60 * 60 * 1000)));
            }).length);
        }
        if(keyNumber === "Bestelllungen"){
            setNumber(orders.filter(v => {
                return v.created_at && (new Date(v.created_at) > new Date((new Date().getTime()) - (days * 24 * 60 * 60 * 1000)));
            }).length);
        }
        if(keyNumber === "Umsatz"){
            setNumber(orders.filter(v => {
                return v.created_at && (new Date(v.created_at) > new Date((new Date().getTime()) - (days * 24 * 60 * 60 * 1000)));
            }).map(o => o.gross_amount).reduce((a, b) => a + b, 0));
        }
    }, [initialized, orders, visitors, days, keyNumber]);

    return (
        <>
            {isEditing ? (
                <>
                    <CardContent sx={{height: 'calc(100% - 125px)', pt: 2, minHeight: 140}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6" color="textPrimary">
                                    Bearbeiten
                                </Typography>
                            </Grid>
                            <Grid item xs={12} mt={2}>
                                <TextField
                                    select
                                    fullWidth={true}
                                    value={keyNumber}
                                    defaultValue={data}
                                    onChange={(event) => {
                                        setKeyNumber(event.target.value);
                                    }}
                                    label="Kernzahl"
                                    inputProps={{
                                        name: 'sync_error_reports',
                                        id: 'outlined-age-native-simple',
                                    }}
                                >
                                    <MenuItem value="Besucherzahl">Besucherzahl</MenuItem>
                                    <MenuItem value="Bestelllungen">Bestellungen</MenuItem>
                                    <MenuItem value="Umsatz">Umsatz</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <NumberFormat
                                    value={parseInt(days)}
                                    fixedDecimalScale
                                    fullWidth
                                    label="Zeitraum"
                                    name="days"
                                    isNumericString
                                    customInput={TextField}
                                    suffix={' Tage'}
                                    decimalScale={0}
                                    type="text"
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    onValueChange={({ value: v }) => {
                                        setDays(parseInt(v));
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    value={name}
                                    fullWidth
                                    label="Name"
                                    name="name"
                                    onChange={(event) => {
                                        setName(event.target.value);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Button onClick={handleSave}>
                            Speichern
                        </Button>
                    </CardActions>
                </>
            ) : (
                <CardContent sx={{height: '100%', p: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    {!initialized ? (
                        <CircularProgress />
                    ) : (
                        <Box>
                            <Grid container direction="row" style={{ display: "flex", alignItems: 'center' }}>
                                {/*<Grid container spacing={2}>*/}
                                {/*    <Grid item xs={12} sm={4}>*/}
                                <Box pt={2.5} pr={2.5}>
                                    {keyNumber === 'Besucherzahl' && (
                                        <User size={40}  />
                                    )}
                                    {keyNumber === 'Bestelllungen' && (
                                        <Truck size={40}  />
                                    )}
                                    {keyNumber === 'Umsatz' && (
                                        <DollarSign size={40}  />
                                    )}
                                </Box>
                                {/*</Grid>*/}
                                {/*<Grid item xs={12} sm={8}>*/}
                                <Box pt={1} mr={2}>
                                    <Typography variant="caption" color="primary.contrast">
                                        {name}
                                    </Typography>
                                    <Typography sx={{lineHeight: 0.9}} variant="h2" color="textPrimary">
                                        <NumberFormat
                                            value={number}
                                            displayType={'text'}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            decimalScale={keyNumber === 'Umsatz' ? 2 : 0}
                                            fixedDecimalScale={keyNumber === 'Umsatz' ? true : false}
                                            suffix={keyNumber === 'Umsatz' ? '€ ' : ''}
                                        />
                                    </Typography>
                                </Box>
                                {/*</Grid>*/}
                                {/*</Grid>*/}
                            </Grid>
                        </Box>
                    )}
                </CardContent>
            )}
        </>
    )
}

export default KeyShopNumber;